import { ExpertiseIcon, ResultsIcon, SolutionsIcon, TechIcon } from "../icons/icons";

export default function ChooseUs() {
    return (
        <>
            <div className="w-full bg-darkBlue flex justify-center p-10">
                <div className="w-full my-10 flex flex-col items-center animate__animated animate__backInUp">
                    <p className="text-5xl font-bold text-white">
                        Why Us?
                    </p>
                    <div className="w-full flex flex-end mt-24">
                        <div className="flex flex-grow justify-center p-2">
                            <div className="w-full flex flex-col items-center mr-10">
                                <ExpertiseIcon />
                                <p className="text-2xl text-white font-semibold my-10">
                                    expertise
                                </p>
                                <p className="text-lg text-white">
                                    Our team of experienced experts have the knowledge and expertise
                                    to deliver innovative IT solutions
                                    that meet your unique needs.
                                </p>
                            </div>

                            <div className="w-full flex flex-col items-center mr-10">
                                <TechIcon />
                                <p className="text-2xl text-white font-semibold my-10">
                                    technology
                                </p>
                                <p className="text-lg text-white">
                                    We stay up to date with the latest trends and technologies in the IT industry, so you can get the most advanced solutions available.
                                </p>
                            </div>

                            <div className="w-full flex flex-col items-center mr-10">
                                <SolutionsIcon />
                                <p className="text-2xl text-white font-semibold my-10">
                                    solutions
                                </p>
                                <p className="text-lg text-white">
                                    We take a personalized approach
                                    to every project, working closely with you to understand your business and create solutions.
                                </p>
                            </div>

                            <div className="w-full flex flex-col items-center">
                                <ResultsIcon />
                                <p className="text-2xl text-white font-semibold my-10">
                                    results
                                </p>
                                <p className="text-lg text-white">
                                    Our track record speaks for itself – we've helped businesses of all sizes and industries achieve their goals with our IT solutions.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}