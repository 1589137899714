
export default function Teams() {
    return (
        <>
            <div className="w-full bg-darkBlue flex justify-center p-10">
                <div className="w-full my-10 flex flex-col items-center animate__animated animate__backInUp">
                    <p className="text-5xl font-bold text-white">
                        Our Team
                    </p>
                    <p className="text-base text-white my-3">
                        The passionate people behind our every projects in Carrado Technologies Limited.
                    </p>

                    <div className="w-full flex flex-wrap justify-between mt-8 mb-3">
                        <div className="flex w-1/4 mr-1 flex-col rounded-xl card my-5">
                            <div className="flex">
                                <img src="./ceo_084472b1-c4d4-4be9-8723-7b57c85df56a.jpeg" className="team_img" alt="ceo" />
                            </div>
                            <div className="mb-4 p-7">
                                <p className="text-lg text-white">
                                    CEO & Co-Founder
                                </p>
                                <p className="my-3 text-lg font-bold text-white">
                                    Chukwuemeka Anyanwu
                                </p>
                            </div>
                        </div>

                        <div className="flex w-1/4 mr-1 flex-col rounded-xl card my-5">
                            <div className="flex flex-grow">
                            </div>
                            <div className="mb-4 p-7">
                                <p className="text-lg text-white">
                                    Director & Co-Founder
                                </p>
                                <p className="my-3 text-lg font-bold text-white">
                                    Chidinma Anyanwu
                                </p>
                            </div>
                        </div>

                        <div className="flex w-1/4 mr-1 flex-col rounded-xl card my-5">
                            <div className="flex">
                                <img src="./26196117_1792557370816767_1720469070771886075_n.jpg" className="team_img" alt="ceo" />
                            </div>
                            <div className="mb-4 p-7">
                                <p className="text-lg text-white">
                                    COO
                                </p>
                                <p className="my-3 text-lg font-bold text-white">
                                    Onaga Christian Chinonso
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}