export default function HeroSection() {
    return (
        <>
            <div className="py-2 flex flex-row">
                <div className="flex flex-grow">
                    <div className="flex flex-col justify-center">
                        <img src="./carrado-tech.png" alt="carrado-tech" style={{ width: '25px', height: '25px' }} />
                    </div>
                    <div className="flex flex-col justify-center mx-3">
                        <h1 className="text-xl font-bold text-darkBlue">Carrado Technologies Limited</h1>
                    </div>
                </div>
                <div className="lg:flex md:flex hidden justify-end p-2">
                    <div className="flex px-4 py-1 mx-3">
                        <span className="text-base text-darkBlue">ABOUT US</span>
                    </div>
                    <div className="flex px-4 py-1 mx-3">
                        <span className="text-base text-darkBlue">SERVICES</span>
                    </div>
                    <div className="flex px-4 py-1 mx-3">
                        <span className="text-base text-darkBlue">PROJECTS</span>
                    </div>
                    <div className="flex px-4 py-1 mx-3">
                        <span className="text-base text-darkBlue">BLOG</span>
                    </div>
                    <div className="flex px-4 py-1 mx-3">
                        <span className="text-base text-darkBlue">CONTACT US</span>
                    </div>
                </div>
            </div>

            <div className="w-full flex justify-center my-7">
                <div className="flex flex-col lg:p-4 my-4 items-center">
                    <div className="flex flex-col animate__animated animate__backInLeft items-center w-full">
                        <div className="my-2 flex">
                            <p className="lg:text-8xl md:text-8xl sm:text-4xl text-4xl text-darkBlue font-bold">YOUR BEST</p>
                        </div>
                        <div className="my-2 flex">
                            <p className="lg:text-8xl md:text-8xl sm:text-4xl text-4xl ml-4 text-darkBlue italic">IT</p>
                            <p className="lg:text-8xl md:text-8xl sm:text-4xl text-4xl text-darkBlue mx-3 font-bold">PARTNER</p>
                        </div>
                    </div>
                    <div className="flex justify-center w-1/2 my-4">
                        <p className="text-lg animate__animated animate__flash text-darkBlue">
                            From custom software development to cybersecurity, our team of experts is dedicated
                            to delivering solutions that are tailored to your unique needs.
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-col p-3 my-5 lg:w-3/5 md:w-3/5 w-full animate__animated animate__backInUp">
                <p className="text-xl my-4 font-semibold text-darkPurple">About.</p>
                <p className="lg:text-5xl md:text-3xl text-2xl font-bold text-darkBlue my-2" style={{ lineHeight: '60px' }}>
                    We believe that <i style={{ fontWeight: 300 }} className="mr-2">technology</i>
                    can transform the world.
                </p>
                <p className="text-lg my-4">
                    That is why we're committed to delivering innovative IT solutions to businesses of all sizes. Our team of experienced professionals is dedicated to helping you achieve your goals and thrive in a rapidly evolving digital landscape. Our
                    goal is to help businesses of all sizes succeed by providing customized solutions that meet their unique needs.
                </p>
            </div>

        </>
    )
}