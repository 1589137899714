
export default function Services() {
    return (
        <>
            <div className="w-full flex justify-center p-10">
                <div className="flex flex-col items-center my-10">
                    <p className="text-5xl font-bold text-darkBlue">
                        Services
                    </p>
                    <div className="w-full flex flex-wrap justify-between mt-8 mb-3">
                        <div className="flex w-1/4 mr-1 flex-col rounded-xl card p-7 my-5">
                            <div className="flex">
                                <div className="flex flex-grow">
                                    <img src="./Web Icon.png" alt="web" style={{ width: 28, height: 28 }} />
                                </div>
                                <div className="flex">
                                    <img src="./Arrow Icon.png" alt="web" style={{ width: 28, height: 28 }} />
                                </div>
                            </div>
                            <div className="mt-10 mb-4">
                                <p className="text-2xl font-bold text-white">
                                    Web
                                    Development
                                </p>
                                <p className="my-3 text-base text-white">
                                    Create websites that are both visually appealing and functional for users.
                                </p>
                            </div>
                        </div>

                        <div className="flex w-1/4 mr-1 flex-col rounded-xl card p-7 my-5">
                            <div className="flex">
                                <div className="flex flex-grow">
                                    <img src="./Mobile Icon.png" alt="web" style={{ width: 28, height: 46 }} />
                                </div>
                                <div className="flex">
                                    <img src="./Arrow Icon.png" alt="web" style={{ width: 28, height: 28 }} />
                                </div>
                            </div>
                            <div className="mt-10 mb-4">
                                <p className="text-2xl font-bold text-white">
                                    Mobile
                                    Development
                                </p>
                                <p className="my-3 text-base text-white">
                                    Create or enhance mobile applications, ensuring optimized user experience.
                                </p>
                            </div>
                        </div>


                        <div className="flex w-1/4 flex-col mr-1 rounded-xl card p-7 my-5">
                            <div className="flex">
                                <div className="flex flex-grow">
                                    <img src="./Security Icon.png" alt="web" style={{ width: 28, height: 28 }} />
                                </div>
                                <div className="flex">
                                    <img src="./Arrow Icon.png" alt="web" style={{ width: 28, height: 28 }} />
                                </div>
                            </div>
                            <div className="mt-10 mb-4">
                                <p className="text-2xl font-bold text-white">
                                    Cyber
                                    Security
                                </p>
                                <p className="my-3 text-base text-white">
                                    Provides advanced protection for your data and systems against threats.
                                </p>
                            </div>
                        </div>

                        
                        <div className="flex w-1/4 flex-col rounded-xl card p-7 my-5">
                            <div className="flex">
                                <div className="flex flex-grow">
                                    <img src="./Marketing Icon.png" alt="web" style={{ width: 28, height: 28 }} />
                                </div>
                                <div className="flex">
                                    <img src="./Arrow Icon.png" alt="web" style={{ width: 28, height: 28 }} />
                                </div>
                            </div>
                            <div className="mt-10 mb-4">
                                <p className="text-2xl font-bold text-white">
                                    Digital
                                    Marketing
                                </p>
                                <p className="my-3 text-base text-white">
                                    Create solutions to help businesses achieve their digital marketing goals.
                                </p>
                            </div>
                        </div>

                        
                        <div className="flex w-1/4 flex-col rounded-xl card p-7 my-5">
                            <div className="flex">
                                <div className="flex flex-grow">
                                    <img src="./Cloud Icon.png" alt="web" style={{ width: 28, height: 28 }} />
                                </div>
                                <div className="flex">
                                    <img src="./Arrow Icon.png" alt="web" style={{ width: 28, height: 28 }} />
                                </div>
                            </div>
                            <div className="mt-10 mb-4">
                                <p className="text-2xl font-bold text-white">
                                    Cloud
                                    Computing
                                </p>
                                <p className="my-3 text-base text-white">
                                    Provides access to scalable computing resources to optimize their operations.
                                </p>
                            </div>
                        </div>


                        <div className="flex w-1/4 flex-col rounded-xl card p-7 my-5">
                            <div className="flex">
                                <div className="flex flex-grow">
                                    <img src="./Coding Icon.png" alt="web" style={{ width: 28, height: 28 }} />
                                </div>
                                <div className="flex">
                                    <img src="./Arrow Icon.png" alt="web" style={{ width: 28, height: 28 }} />
                                </div>
                            </div>
                            <div className="mt-10 mb-4">
                                <p className="text-2xl font-bold text-white">
                                    Software
                                    Development
                                </p>
                                <p className="my-3 text-base text-white">
                                    Provides customized solutions to create and maintain software applications.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}