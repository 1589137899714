import ChooseUs from "./layouts/ChooseUs";
import HeroSection from "./layouts/HeroSection";
import 'animate.css';
import Services from "./layouts/Services";
import Teams from "./layouts/Teams";
import ContactUs from "./layouts/ContactUs";

function App() {
  return (
    <>
      <div className="w-full p-5 flex flex-col h-full">
        <HeroSection />
      </div>
      <div className="w-full flex flex-col h-full">
        <ChooseUs />
      </div>
      <div className="w-full flex flex-col h-full">
        <Services />
      </div>
      <div className="w-full flex flex-col h-full">
        <Teams />
      </div>
      <div className="w-full flex flex-col h-full">
        <ContactUs />
        <div className="flex flex-grow justify-center">
          <div className="flex flex-col justify-center">
            <img src="./carrado-tech.png" alt="carrado-tech" style={{ width: '25px', height: '25px' }} />
          </div>
          <div className="flex flex-col justify-center mx-3">
            <h1 className="text-xl font-bold text-darkBlue">Carrado Technologies Limited</h1>
          </div>
        </div>
        <div className="flex flex-grow mt-5 justify-center">
          <p className="text-base text-darkBlue">© 2023 All Rights Reserved</p>
        </div>
      </div>
    </>
  );
}

export default App;
