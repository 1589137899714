export default function ContactUs() {
    return <>
        <div className="w-full flex justify-center p-10 mb-10">
            <div className="flex flex-col items-center">
                <p className="text-5xl font-bold text-darkBlue">
                    Contact Us
                </p>
                <div className="w-full flex justify-between mt-8 mb-3">
                    <div className="flex w-1/3 mr-3 flex-col rounded-xl card p-7 my-5">
                        <div className="flex">
                            <div className="flex flex-grow">
                            </div>
                            <div className="flex">
                            </div>
                        </div>
                        <div className="mt-10 mb-4">
                            <p className="text-2xl font-bold text-white">
                                HEAD OFFICE
                            </p>
                            <p className="my-3 text-base text-white">
                                7 Harmony Road Ugwuorie Iji-Nike Enugu, Enugu State, Nigeria
                            </p>
                        </div>
                    </div>

                    
                    <div className="flex w-1/3 mr-3 flex-col rounded-xl card p-7 my-5">
                        <div className="flex">
                            <div className="flex flex-grow">
                            </div>
                            <div className="flex">
                            </div>
                        </div>
                        <div className="mt-10 mb-4">
                            <p className="text-2xl font-bold text-white">
                               CALL
                            </p>
                            <p className="my-3 text-base text-white">
                               (+234)81-632-76826
                            </p>
                        </div>
                    </div>


                    <div className="flex w-1/3 flex-col rounded-xl card p-7 my-5">
                        <div className="flex">
                            <div className="flex flex-grow">
                            </div>
                            <div className="flex">
                            </div>
                        </div>
                        <div className="mt-10 mb-4">
                            <p className="text-2xl font-bold text-white">
                                EMAIL
                            </p>
                            <p className="my-3 text-base text-white">
                                carradotechnologiesltd@gmail.com
                            </p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </>
}